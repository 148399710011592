<template>
    <div class="basket full-height d-flex flex-column">
        <Breadcrumbs :active="1" showNbrProducts class="basket-breadcrumbs"></Breadcrumbs>

        <div v-if="nbrProductsInBasket < 1" class="text-center" >{{$t('emptyBasket')}}</div>

        <div v-else class="cart-products">

            <div v-for="product in basketProducts" :key="product.id" class="product-container mb-2">

                <div :id="'id-' + product.id" class="swiper-container">
                    <div :ref="'product-'+ product.id" class="swiper-wrapper">
                        <div class="swiper-slide">
                            <ProductBloc :ref="'product-' + product.id"  :product="product.product" :qty="product.qty" :productIsDeleted="product.isDeleted" showMore></ProductBloc>
                        </div>

                        <div class="swiper-slide  ps-1" style="width: unset;" v-if="isNull(product.isDeleted)">
                            <div class="swipe-delete" @click="deleteClickHandler(product)" :ref="'delete-'+ product.id" >
                                <v-icon color="white" large>mdi-trash-can-outline</v-icon>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="basket-footer">
            <v-divider class="mx-4 primary"></v-divider>
            <div class="white px-8 py-3 d-flex flex-column align-center justify-center">
                <div class="d-flex align-center basket-amount">
                    <span class="primary--text font-weight-bold text-h5">Total:</span>
                    <v-spacer></v-spacer>
                    <span class="primary--text font-weight-bold text-h5">{{formatPrice(totalAmount)}}</span>
                </div>
                <div class="full-width d-flex flex-column align-center justify-center">
                    <v-btn class="mt-3 mb-2" fab elevation="0" dark color="primary" width="56" height="56" @click="$router.push({ name: 'ScanProduct' })"> <v-img width="24" height="24" src="@/assets/img/icon-scan.png" contain></v-img> </v-btn>
                </div>
                <v-btn :class="{'py-5 basket-footer-btn': true, 'disabled': hideCheckoutButton}" color="primary" @click="handleCheckoutClick()" rounded> {{$t("finalizeCheckoutBasket")}} </v-btn>
            </div>
        </div>

        <v-dialog v-model="showDeleteModal" width="400" content-class="rounded-xl" >
            <v-card>
                <v-card-title>
                    <span class="primary--text text-h5">Article supprimé</span>
                </v-card-title>

                <v-card-text>
                    <span class="primary--text">Pensez à remettre le produit à sa place.</span>
                </v-card-text>

                <v-divider class="primary mx-8"></v-divider>

                <v-card-actions class="justify-center">
                    <v-btn outlined small color="primary" class="mb-2 me-2 delete-modal-action" @click="cancelDelete">Non, je garde</v-btn>
                    <v-btn small color="primary" class="mb-2 delete-modal-action" @click="confirmDelete">C'est fait</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import ProductBloc from "@/components/ProductBloc";
import {pageInfoMixin} from "@/mixins/pageInfoMixin"
import { Swiper } from 'swiper'
import 'swiper/swiper-bundle.css'

export default {
  name: 'Basket',
  components: {ProductBloc, Breadcrumbs},
  mixins: [pageInfoMixin],
  data() {
    return {
      productToDelete: null,
      showDeleteModal: false,
      selectedSwiper: null,
    }
  },
  mounted() {
    setTimeout(this.initSwiper, 1000)
    this.removeTempDeletedProducts()
  },
  computed: {
    hideCheckoutButton() {
      if(this.basketProducts.length == 1 && this.productToDelete != null){
        return true
      } else if(this.basketProducts.length > 0){
        return false
      }
      return true
    },
    totalAmount() {
      if(this.productToDelete != null){
        return this.basketTotalAmount - this.productToDelete.product.price * this.productToDelete.qty
      }else{
        return this.basketTotalAmount
      }
    },
  },
  methods: {
    initSwiper() {
      this.basketProducts.forEach((product) => {
        let swiper = new Swiper('#id-' + product.id, {
          initialSlide: 0,
          resistanceRatio: 0.85,
          slidesPerView: 'auto',
          speed: 300,
          effect: 'fade'
        })

        let productElmt = this.$refs['product-' + product.id][1];
        this.$refs['delete-' + product.id][0].setAttribute('style', `height: ${productElmt.offsetHeight}px;`)

        swiper.on('reachEnd', () => {
          this.selectedSwiper = swiper
        })
      })
    },
    deleteClickHandler(product) {
      this.productToDelete = product
      this.showDeleteModal = true
    },
    confirmDelete() {
      this.setProductIsDeleted(this.productToDelete)
      this.showDeleteModal = false;
      setTimeout(() => {
        this.removeProductInBasket({product: this.productToDelete})
        this.productToDelete = null
      },5000);
    },
    cancelDelete() {
      this.productToDelete = null
      this.showDeleteModal = false
      this.selectedSwiper.slideTo(0)
    },
    async handleCheckoutClick() {
      if (this.nbrProductsInBasket > 0) {
        try {
          this.removeTempDeletedProducts()
          await this.updateBasketInDatabase()
          this.$router.push({name: "PaymentMethods"})
        } catch (e) {
          console.error(e)
        }
      }
    },
    removeTempDeletedProducts(){
      this.basketProducts.forEach((basketProduct) => {
        if (basketProduct.isDeleted != null && basketProduct.isDeleted) {
          this.removeProductInBasket({product: basketProduct})
        }
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    this.removeTempDeletedProducts()
    next();
  }
}
</script>

<style scoped>
.basket-breadcrumbs{
    position: fixed;
    width: 100%;
    background: white;
    z-index: 10;
}

.swiper-container{
    margin: 0 16px;
}

.swipe-delete{
    background-color: red;
    display: flex;
    width: 125px;
    justify-content: center;
    border-radius: 64px;

}
.swipe-delete .icon{
    font-size: 2rem;
}

.basket-footer .basket-amount{
    width: 100%;
}

.basket-footer-btn{
    width: 100%;
}
.basket-footer-btn.disabled{
    opacity: 0.55;
}

.basket-footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
    padding-top: 20px;
    background: white;
}

.cart-products{
    margin-bottom: 174px;
    padding-top: 72px;
    padding-bottom: 72px;
    overflow-y: scroll;
}

.basket >>>.delete-modal{
    border-radius: 15px !important;;
}

.basket{
    overflow: hidden;
}

.delete-modal-action{
    border-radius: 8px;
}
</style>

